import Lenis from '@studio-freight/lenis'


import { getProject, types, val } from '@theatre/core'

import studio from '@theatre/studio'
import projectState from '../public/2024-01-08_path.json'



import * as THREE from 'three'
import { GLTFLoader } from 'three/addons/loaders/GLTFLoader.js';
import { DRACOLoader } from 'three/examples/jsm/loaders/DRACOLoader.js';
import { OrbitControls } from 'three/addons/controls/OrbitControls.js';

import { TextureLoader } from 'three';
import { MeshBasicMaterial } from 'three';

import { DirectionalLight, HemisphereLight } from 'three';



// Programatically generate text
import { FontLoader } from 'three/addons/loaders/FontLoader.js';
import { TextGeometry } from 'three/addons/geometries/TextGeometry.js';


// Glow / Bloom
import { EffectComposer } from 'three/addons/postprocessing/EffectComposer.js';
import { RenderPass } from 'three/addons/postprocessing/RenderPass.js';
import { ShaderPass } from 'three/addons/postprocessing/ShaderPass.js';
import { UnrealBloomPass } from 'three/addons/postprocessing/UnrealBloomPass.js';
import { OutputPass } from 'three/addons/postprocessing/OutputPass.js';

const lenis = new Lenis()

// Create the scene
const scene = new THREE.Scene();

const blackcolor = new THREE.Color( 0x222222 );
scene.background = blackcolor;
//const whitecolor = new THREE.Color( 0xdddddd );
//scene.background = whitecolor;


// Sets a 12 by 12 gird helper
const gridHelper = new THREE.GridHelper(1, 1);
scene.add(gridHelper);

// Sets the x, y, and z axes with each having a length of 4
const axesHelper = new THREE.AxesHelper(1);
scene.add(axesHelper);


var perspective = 75;
var bloomenabled = true;

// Check if we're on mobile
var onmobile = screen.width <= 600;
// onmobile = true; // Debugging

if (onmobile)
{
    console.log("Small screen, going mobile.");
    perspective = 110;
    bloomenabled = true;
}
else
{
    console.log("Larger screen, normal perspective");
}

// Create the camera
const camera = new THREE.PerspectiveCamera(perspective, window.innerWidth / window.innerHeight, 0.01, 7000);
camera.position.set(0, 1, 5);

// Create the renderer
var renderer;

if (onmobile)
{
    console.log("Anti-aliasing is OFF, for performance");
    renderer = new THREE.WebGLRenderer();
}
else
{
    console.log("Anti-aliasing is ON.");
    renderer = new THREE.WebGLRenderer( { antialias: true } );
}

renderer.setSize(window.innerWidth, window.innerHeight);
renderer.setPixelRatio(2); // Math.max(2,window.devicePixelRatio)); // Performance
// renderer.toneMapping = THREE.ReinhardToneMapping; Why does this crash
renderer.domElement.classList.add('my-custom-class');
document.body.appendChild(renderer.domElement);


const RAILS_STATE = 0;
const FREE_STATE = 1;
const INSIDE_STATE = 2;

var state = RAILS_STATE;

// Theatre.js

// studio.initialize()


var project;

// Vite
if (import.meta.env.DEV)
{
    console.log("Environment is DEV");
    studio.initialize()
    // project = getProject('SOME thing');
    project = getProject('SOME thing', { state: projectState })
    //studio.extend(extension)
}
else
{
    console.log("Environment is PRODUCTION");
    project = getProject('SOME thing', { state: projectState })
}

const sheet = project.sheet('My sheet scene thing')
const sequenceLength = val(sheet.sequence.pointer.length);
sheet.sequence.position = 0.1

//project.ready.then(() => sheet.sequence.play({ iterationCount: Infinity }))


var welcomediv = document.getElementById("welcomediv");
var menubuttonsdiv = document.getElementById("menubuttonsdiv");

const cameraTheatreObject = sheet.object('TheatreCamera', {

    opacity: types.number(1, { range: [0, 1] }),
    rotation: types.compound({
        x: types.number(camera.rotation.x, { range: [-4, 4] }),
        y: types.number(camera.rotation.y, { range: [-4, 14] }),
        z: types.number(camera.rotation.z, { range: [-4, 4] }),
    }),
    position: types.compound({
        x: types.number(camera.position.x, { range: [-10, 10] }),
        y: types.number(camera.position.y, { range: [ -1, 20] }),
        z: types.number(camera.position.z, { range: [-10, 10] }),
    }),
})

cameraTheatreObject.onValuesChange((values) => {
    welcomediv.style.opacity = values.opacity;
    menubuttonsdiv.style.opacity = 1.0-values.opacity;
    camera.rotation.set(values.rotation.x, values.rotation.y, values.rotation.z)
    camera.position.set(values.position.x, values.position.y, values.position.z)
})




const loadingManager = new THREE.LoadingManager();
var doneloading = false;

loadingManager.onStart = function(url, item, total) {
    console.log(`Started loading: ${url}`);
}

const progressBar = document.getElementById('progress-bar');

loadingManager.onProgress = function(url, loaded, total) {
    progressBar.value = (loaded / total) * 100;
}

const progressBarContainer = document.querySelector(".progress-bar-container");

loadingManager.onLoad = function()
{
    console.log("Onload fired.");

    // Check if everything has actually loaded, every 100ms
    const intervalId = setInterval(() => {
        console.log("Checking...");

        if (doneloading)
        {
            console.log("Truly done loading!");
            clearInterval(intervalId);
            console.log("Hiding loading screen");
            progressBarContainer.style.display = 'none';
            sheet.sequence.position = 0.000010568986194127873;
            lenis.scrollTo(0.1); // progress = 0.000010568986194127873;

        }
        else
        {
            console.log("... not quite...");
        }

    }, 500);

}

// loadingManager.onError = function(url) {
//     console.error(`Got a problem loading: ${url}`);
// }



// Create Draco loader
let dracoLoader = new DRACOLoader();
dracoLoader.setDecoderPath('/draco/'); // set path to where draco_decoder.js is located

console.log("The draco loader is:");
console.log(dracoLoader);

const gltfLoader = new GLTFLoader(loadingManager);
gltfLoader.setDRACOLoader(dracoLoader);


// Load the texture in
const textureLoader = new TextureLoader(loadingManager);
const plantsTexture = textureLoader.load('plants.jpg');

const reflectionTextureLoader = new THREE.TextureLoader(loadingManager);
const reflectionTexture = reflectionTextureLoader.load('sydney_skyline_clouds.png');
reflectionTexture.mapping = THREE.EquirectangularReflectionMapping;



// All the materials we use

// Glass

const glassMaterial = new THREE.MeshStandardMaterial({
    roughness: 0.1,
    metalness: 0.9,
    color: 0xFFFFFF
});

glassMaterial.envMap = reflectionTexture;

//
// Glowing neon material

const emissiveColor = new THREE.Color(0x00ff00);
const utsMaterial = new THREE.MeshStandardMaterial({
    color: 0x00ff00, // Base color (you can specify your desired color)
    emissive: emissiveColor, // Doesn't really bloom without this for some reason.
    metalness: 1, // Metalness value
    roughness: 1 // Roughness value
    //transmission: 1, // Transmission value This is bullshit
});


// Plants
plantsTexture.repeat.set(0.1, 0.1);

const plantsMaterial = new THREE.MeshStandardMaterial({
  map: plantsTexture
});

// Flat normal texture

const buildingsMaterial = new THREE.MeshStandardMaterial({
    //  color: 0x00ff00, // Base color (you can specify your desired color)
    //metalness: 1, // Metalness value
    roughness: 1 // Roughness value
});


// Create the loader and load the GLB model
//const loader = new GLTFLoader();

let buildingsModel;
gltfLoader.load('/2024-01-06.glb', (gltf) => {
  buildingsModel = gltf.scene;
  buildingsModel.scale.set(1, 1, 1);
  // glbModel.material = utsmaterial;
  //buildingsModel.layers.enable( BLOOM_SCENE );

  buildingsModel.material = buildingsMaterial;

  // Assign the material to your model
  buildingsModel.traverse((child) => {
      console.log(child);
      console.log("* " + child.name + " *");

      if (child.name)
      {
          console.log("Got name and the name is: " + child.name);

          // if(child.material != null && child.material.name == "Glass_UTS_B")
          if(child.material != null && child.material.name.includes("Glass"))
          {
              child.material = glassMaterial;
          }

          if (bloomenabled)
          {
              // if ((child.name == "Neon") && child.isMesh)
              if(child.material != null && child.material.name == "Glow")
              {
                  // child.userData.title = 'UTS child Glow only #####################';
                  console.log("BLOOMED IT #######");
                  child.layers.enable( BLOOM_SCENE );
                  child.material = utsMaterial;
              }
          }

          if ((child.name == "Glass_") && child.isMesh)
          {
              console.log("GLASSED X #######");
              child.material = glassMaterial;
          }

          if ((child.name == "Main_Glass") && child.isMesh)
          {
              console.log("GLASSED IT #######");
              child.material = glassMaterial;
          }

          if ((child.name == "Plants") && child.isMesh)
          {
              console.log("PLANTED IT #######");
              child.material = plantsMaterial;
          }

      }
      else
      {
          console.log("No name");
      }

  });

  scene.add(buildingsModel);
  console.log("------> Added building model just now");
  doneloading = true;
});


/*
let adjglassModel;
gltfLoader.load('8_adj_glass.gltf', (gltf) => {
  adjglassModel = gltf.scene;
  adjglassModel.scale.set(1, 1, 1);
  adjglassModel.userData.title = 'Other_adjglass';


  // Create a MeshStandardMaterial without specifying a texture
  const adjglassMaterial = new THREE.MeshStandardMaterial({
      roughness: 0.1,
      metalness: 0.9,
      color: 0xFFFFFF
  });

  adjglassMaterial.envMap = reflectionTexture;

  adjglassModel.material = adjglassMaterial;
  scene.add(adjglassModel);
});
*/


/*
let grateModel;
gltfLoader.load('/8_uts_mesh.glb', (gltf) => {
  grateModel = gltf.scene;
  grateModel.scale.set(1, 1, 1);
  // glbModel.material = utsmaterial;
  //grateModel.layers.enable( BLOOM_SCENE );

  grateModel.userData.title = 'Grate Only';

  // Create a MeshStandardMaterial without specifying a texture
  const grateMaterial = new THREE.MeshStandardMaterial({
    color: 0xcccccc, // Base color (you can specify your desired color)
    metalness: 1, // Metalness value
    roughness: 1 // Roughness value
  });

  grateModel.material = grateMaterial;
  scene.add(grateModel);
});

*/

/*
const emissiveColor = new THREE.Color(0x00ff00); // Emissive color TODO CHange this to red


let utsModelLEDs;
gltfLoader.load('/8_uts_glow_only.glb', (gltf) => {
  utsModelLEDs = gltf.scene;
  utsModelLEDs.scale.set(1, 1, 1);
  // glbModel.material = utsmaterial;
  //utsModelLEDs.layers.enable( BLOOM_SCENE );

  utsModelLEDs.userData.title = 'UTS Glow only';

  // Create a MeshStandardMaterial without specifying a texture
  const utsMaterial = new THREE.MeshStandardMaterial({
    color: 0x00ff00, // Base color (you can specify your desired color)
    emissive: emissiveColor,
    metalness: 1, // Metalness value
    roughness: 1 // Roughness value
    //transmission: 1, // Transmission value This is bullshit
  });

  // Assign the material to your model
  utsModelLEDs.traverse((child) => {
    if (child.isMesh) {
      child.userData.title = 'UTS child Glow only';
      child.layers.enable( BLOOM_SCENE );
      child.material = utsMaterial;
    }
  });

  scene.add(utsModelLEDs);
});

*/





const BLOOM_SCENE = 1;

const bloomLayer = new THREE.Layers();
bloomLayer.set( BLOOM_SCENE );

const params = {
    threshold: 0,
    strength: 1,
    radius: 0.5,
    exposure: 1
};

const darkMaterial = new THREE.MeshBasicMaterial( { color: 'black' } );
const materials = {};



// Add fog
/*
const fogColor = new THREE.Color(0xffffff); // Color of the fog
const fogDensity = 0.02; // Adjust the density as needed

// Create and set the fog
scene.fog = new THREE.FogExp2(fogColor, fogDensity);
*/




// Text

var textMesh;

const fontLoader = new FontLoader(loadingManager);

fontLoader.load( 'fonts/helvetiker_regular.typeface.json', function ( font ) {

    const textGeometry = new TextGeometry( 'sike', {
        font: font,
        size: 1,
        height: 0.5,
        curveSegments: 12,
        bevelEnabled: true,
        bevelThickness: 0.01,
        bevelSize: 0.01,
        bevelOffset: 0,
        bevelSegments: 5
    } );


    // Create a material for the text
    // const textMaterial = new THREE.MeshBasicMaterial({ color: 0x00ff00 });
    const textMaterial = new THREE.MeshPhongMaterial( { color: 0xffffff, flatShading: true } );

    // Create a mesh with the text geometry and material
    textMesh = new THREE.Mesh(textGeometry, textMaterial);
    textMesh.position.set(4, -0.2, -4.38);
    textMesh.rotation.y = Math.PI / 2;

    // Add the text mesh to the scene
    scene.add(textMesh);

} );



const renderScene = new RenderPass( scene, camera );

const outputPass = new OutputPass();

const finalComposer = new EffectComposer( renderer );
var bloomComposer;
var mixPass;

if (bloomenabled)
{
    const bloomPass = new UnrealBloomPass( new THREE.Vector2( window.innerWidth, window.innerHeight ), 1.5, 0.4, 0.85 );
    bloomPass.threshold = params.threshold;
    bloomPass.strength = params.strength;
    bloomPass.radius = params.radius;

    bloomComposer = new EffectComposer( renderer );
    bloomComposer.renderToScreen = false;
    bloomComposer.addPass( renderScene );
    bloomComposer.addPass( bloomPass );

    mixPass = new ShaderPass(
        new THREE.ShaderMaterial( {
            uniforms: {
                baseTexture: { value: null },
                bloomTexture: { value: bloomComposer.renderTarget2.texture }
            },
            vertexShader: document.getElementById( 'vertexshader' ).textContent,
            fragmentShader: document.getElementById( 'fragmentshader' ).textContent,
            defines: {}
        } ), 'baseTexture'
    );
    mixPass.needsSwap = true;

}

finalComposer.addPass( renderScene );

if (bloomenabled)
{
    finalComposer.addPass( mixPass );
}

finalComposer.addPass( outputPass );



var rayables = [];

var roundcube;
var halo;

setupScene();

function setupScene() {


    scene.traverse( disposeMaterial );
    scene.children.length = 0;


    // rayables.push(roundcube);


    // Grab the sculpture
    halo = scene.getObjectByName("Sculpture")
    console.log("The halo:");
    console.log(halo);
    //rayables.push(halo);


    /*
    const geometry = new THREE.IcosahedronGeometry( 1, 15 );

    for ( let i = 0; i < 50; i ++ ) {

        const color = new THREE.Color();
        color.setHSL( Math.random(), 0.7, Math.random() * 0.2 + 0.05 );

        const material = new THREE.MeshBasicMaterial( { color: color } );
        const sphere = new THREE.Mesh( geometry, material );
        sphere.userData.title = 'Sphere number ' + i;
        sphere.position.x = Math.random() * 10 - 5;
        sphere.position.y = Math.random() * 10 - 5;
        sphere.position.z = Math.random() * 10 - 5;
        sphere.position.normalize().multiplyScalar( Math.random() * 4.0 + 2.0 );
        sphere.scale.setScalar( Math.random() * Math.random() + 0.1 );
        scene.add( sphere );

        if ( Math.random() < 0.25 ) sphere.layers.enable( BLOOM_SCENE );

    }
    */

    render();

}

function disposeMaterial( obj ) {

    if ( obj.material ) {

        try
        {
            obj.material.dispose();
        }
        catch
        {
            console.log("Failed to dispose of :");
            console.log(obj);
        }

    }

}

function render()
{

    if (bloomenabled)
    {
        scene.traverse( darkenNonBloomed );
        bloomComposer.render();
        scene.traverse( restoreMaterial );
    }

    // render the entire scene, then render bloom scene on top
    finalComposer.render();

}

function darkenNonBloomed( obj )
{

    /*
    console.log("------------------------");
    console.log("We're looking at an obj: ");
    console.log(obj);
    if (obj.userData && obj.userData.title)
    {
        console.log("Got it, and the title is: " + obj.userData.title);
    }
    else
    {
        console.log("Don't got title.");
    }
    */

    if ( obj.isMesh && bloomLayer.test( obj.layers ) === false )
    {
        //console.log("Darkening it.");

        materials[ obj.uuid ] = obj.material;
        obj.material = darkMaterial;

    }
    else
    {
        //console.log("NOT darkening it.");
    }

}

function restoreMaterial( obj ) {

    if ( materials[ obj.uuid ] ) {

        obj.material = materials[ obj.uuid ];
        delete materials[ obj.uuid ];

    }

}


// round-edged box
  function createBoxWithRoundedEdges(x, y, z, radius0, smoothness) {
    let shape = new THREE.Shape();
    let eps = 0.00001;
    let radius = radius0 - eps;
    shape.absarc(eps, eps, eps, -Math.PI / 2, -Math.PI, true);
    shape.absarc(eps, y - radius * 2, eps, Math.PI, Math.PI / 2, true);
    shape.absarc(x - radius * 2, y - radius * 2, eps, Math.PI / 2, 0, true);
    shape.absarc(x - radius * 2, eps, eps, 0, -Math.PI / 2, true);
    let geometry = new THREE.ExtrudeGeometry(shape, {
      amount: z - radius0 * 2,
      bevelEnabled: true,
      bevelSegments: smoothness * 2,
      steps: 1,
      bevelSize: radius,
      bevelThickness: radius0,
      curveSegments: smoothness
    });

    geometry.center();

    return geometry;
  }


// Add background music
// create an AudioListener and add it to the camera
const listener = new THREE.AudioListener();
camera.add( listener );

// create a global audio source
const sound = new THREE.Audio( listener );

// load a sound and set it as the Audio object's buffer
const audioLoader = new THREE.AudioLoader(loadingManager);
audioLoader.load('MUSIC_OUT_final.ogg', function( buffer ) {
    sound.setBuffer( buffer );
    sound.setLoop( true );
    sound.setVolume( 0.5 );
    sound.setLoopStart( 3.0 );
    sound.setLoopEnd( 54.0 );
    // sound.autoplay = true;
    if (import.meta.env.DEV)
    {
        console.log("In dev, not playing music.");
    }
    else
    {
        console.log("In production, playing music.");
        sound.play();
        sound.autoplay = true;
    }

    //sound.play();
    //console.log("It's playing!");
});




// Get the button element
const soundbutton = document.getElementById('soundbutton');

// Flag to track music state
let isMusicPlaying = false;

// Event listener for the button click
soundbutton.addEventListener('click', function()
{
    console.log("Clicked button.");
    if (isMusicPlaying)
    {
        console.log("Pausing music");
        sound.pause();
        // soundbutton.textContent = 'Play Music';
        soundbutton.innerHTML = '<span class="material-symbols-outlined">volume_off</span>';
    }
    else
    {
        console.log("Playing music");
        sound.play();
        // soundbutton.textContent = 'Pause Music';
        soundbutton.innerHTML = '<span class="material-symbols-outlined">volume_up</span>';
    }

    isMusicPlaying = !isMusicPlaying;
});







// Add some lights

// Create a directional light
const directionalLight = new DirectionalLight(0xffffff, 1);
directionalLight.position.set(1, 2, 1); // Set the light's position

// Create a hemisphere light
const hemisphereLight = new HemisphereLight(0x808080, 0x606060, 1);
hemisphereLight.position.set(0, 3, 0); // Set the light's position

scene.add(directionalLight);
scene.add(hemisphereLight);



lenis.on('scroll', (e) => {

    if(doneloading)
    {
        if (state == RAILS_STATE)
        {
            console.log("Lenis progress = " + e.progress);
            // ScrollTrigger.update()
            sheet.sequence.position = e.progress * sequenceLength;

            if (e.progress > 0.98)
            {
                console.log("Going inside.");
                // Show loading screen here so the user doesn't feel lost.
                progressBarContainer.style.display = 'block';

                window.location.href = "/room.html";
            }

            if (e.progress > 0.05)
            {
                console.log("Hit 5% progress");
                if (sound.isPlaying)
                {
                    console.log("Already playing sound.");
                }
                else
                {
                    console.log("Playing now!");
                    sound.play();
                }
            }

        }
        else
        {
            console.log("Not in rails mode, skipping scroll handling.");
        }

    }
    else
    {
        console.log("NOT YET!");
        return;
    }

})



/*
var scrolltemp = 0.0;
window.addEventListener('wheel', (event) => {
    scrolltemp += 0.002;
    console.log("Scrolled, scrolltemp = " + scrolltemp);
    sheet.sequence.position = scrolltemp * sequenceLength;
});
*/



// Create a controls object for keyboard navigation
// const controls = new PointerLockControls(camera, document.body);
//scene.add(controls.getObject());

const myorbitalcontrols = new OrbitControls(camera, renderer.domElement)
myorbitalcontrols.enableDamping = true;
myorbitalcontrols.enabled = (state == FREE_STATE);
// controls.addEventListener( 'change', render ); // This was an old way of animating
//

document.addEventListener('keydown', function(event) {
  if (event.key === 'h')
  {
    const target = new THREE.Vector3(0, 0, 0); // The position to look at (0, 0, 0)
    camera.lookAt(target); // Replace 'camera' with your actual camera object
  }
  else if (event.key === 'f')
  {
      if (state == FREE_STATE)
      {
        state = RAILS_STATE;
      }
      else
      {
        state = FREE_STATE;
      }

      myorbitalcontrols.enabled = (state == FREE_STATE);
  }
});


window.addEventListener('resize', () => {

    camera.aspect = window.innerWidth / window.innerHeight;
    camera.updateProjectionMatrix();
    renderer.setSize(window.innerWidth, window.innerHeight);
    renderer.setPixelRatio(Math.max(2,window.devicePixelRatio)); // Performance
});


if (import.meta.env.DEV)
{
    console.log("DEV; Not listening for double click.");
}
else
{
    window.addEventListener('dblclick', () => {

        const fullscreenElement = document.fullscreenElement || document.webkitFullscreenElement

        if(!fullscreenElement)
        {
            if(document.body.requestFullscreen)
            {
                document.body.requestFullscreen()
            }
            else if(document.body.webkitRequestFullscreen)
            {
                document.body.webkitRequestFullscreen()
            }
        }
        else
        {
            if(document.exitFullscreen)
            {
                document.exitFullscreen()
            }
            else if(document.webkitExitFullscreen)
            {
                document.webkitExitFullscreen()
            }
        }

    });
}



// Clicking on 3D Stuff

const raycaster = new THREE.Raycaster();

const mouse = new THREE.Vector2();




window.addEventListener( 'pointerup', onPointerUp );

function onPointerUp( event )
{
    console.log("Pointer up!");
    mouse.x = ( event.clientX / window.innerWidth ) * 2 - 1;
    mouse.y = - ( event.clientY / window.innerHeight ) * 2 + 1;

    raycaster.setFromCamera( mouse, camera );
    const intersects = raycaster.intersectObjects( rayables, false ); // ,scene.children
    if ( intersects.length > 0 ) {

        const object = intersects[ 0 ].object;
        object.layers.toggle( BLOOM_SCENE );
        render();

    }
}





// For some reason this skybox only works if it's later in the file.
// Skybox
let skybox_material_array = [];
let texture_ft = textureLoader.load('clouds1_north.jpeg');
let texture_bk = textureLoader.load('clouds1_south.jpeg');
let texture_up = textureLoader.load('clouds1_up.jpeg');
let texture_dn = textureLoader.load('clouds1_down.jpeg');
let texture_rt = textureLoader.load('clouds1_east.jpeg');
let texture_lf = textureLoader.load('clouds1_west.jpeg');

skybox_material_array.push(new THREE.MeshBasicMaterial( { map: texture_bk }));
skybox_material_array.push(new THREE.MeshBasicMaterial( { map: texture_ft }));
skybox_material_array.push(new THREE.MeshBasicMaterial( { map: texture_up }));
skybox_material_array.push(new THREE.MeshBasicMaterial( { map: texture_dn }));
skybox_material_array.push(new THREE.MeshBasicMaterial( { map: texture_rt }));
skybox_material_array.push(new THREE.MeshBasicMaterial( { map: texture_lf }));

for (let i = 0; i < 6; i++)
{
  skybox_material_array[i].side = THREE.BackSide;
}

let skyboxGeo = new THREE.BoxGeometry(10000, 10000, 10000);
let skybox = new THREE.Mesh( skyboxGeo, skybox_material_array );
scene.add( skybox );

console.log("SKYBOX:");
console.log(skybox);



// Update function to handle movement
function update(time)
{

  if(state==FREE_STATE)
  {
    myorbitalcontrols.update();
  }
  else if (state==RAILS_STATE)
  {
      lenis.raf(time)
  }

  requestAnimationFrame(update);

  //renderer.render(scene, camera);
  render();
}

update(); // Start the update loop

